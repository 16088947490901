<template>
  <div v-if="isReady" class="Reports__View">
      <template v-if="isLoading">
        <div class="loading">
          <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false" />
        </div>
      </template>
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>יחידות לימוד</template>
      <template #button>
        <!-- <b-button v-if="isAdmin"  @click="showNewEntity">הוספת דוח חדש</b-button> -->
        <div style="display: flex; align-items: center; gap: 10px;">
          <a @click="addLesson" class="link-button">
            הוספת יחידת לימוד לקורס
          </a>
          <a @click="reOrderLessons" class="button-close">
            סדר יחידות לימוד מחדש
          </a>
        </div>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import CoursesService from "@/services/CoursesService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import LessonEntity from "@/views/LessonEntity.vue";
import DialogAddLesson from '@/components/DialogAddLesson.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import VehicleContractEntity from "@/views/VehicleContractEntity.vue";
// import TableActions from "@/services/TableActionsService";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import NewReportEntity from "@/components/Entities/NewReportEntity.vue";
import { saveAs } from "file-saver";
import LessonService from "../services/LessonService";
import axios from "axios";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
      isLoading: false
    };
  },
  components: {
    EntityListLayout,
  },
  computed: {
    isUserProfile() {
      return this.$store.state.auth.profileId;
    },
    isAdmin() {
      return this.$store.state.auth?.user?.claims?.isAdmin === "True";
    },
    tabName() {
      return this.selectedTab && this.selectedTab.title;
    },
    courseId() {
      return this.$store.state.auth.course.id;
    },
    excelMimeType() {
      return "application/vnd.ms-excel";
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    isEndUser() {
      return !this.isLeasingCompany;
    },
    isReady() {
      return this.isLeasingCompany !== null;
    },
  },
  created() {
    if (this.isReady) {
      this.create();
    }
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("lessons");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: LessonEntity,
        preview: true,
        readOnly: !this.isAdmin,
        title: "פרטי יחידת לימוד",
        recordUrl: "lessons",
        recordTitle: "הצג יחידת לימוד"
      };

      // const excelExport = [
      //   {
      //     title: "יצוא טבלה",
      //     action: this.export,
      //   },
      //   {
      //     title: "יצוא מקובץ לפי עיריות",
      //     action: this.exportByMunic,
      //   },
      //   {
      //     title: "יצוא מקובץ לפי לקוח",
      //     action: this.exportByClient,
      //   },
      // ];
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.CourseNumber, checked: true },
        // { ...lessonsFields.SupportingMaterials, checked: true },
        { ...lessonsFields.LessonName, checked: true },
        { ...lessonsFields.LessonColor, checked: true },
        { ...lessonsFields.LessonType, checked: true },
        { ...lessonsFields.Status, checked: true },
        { ...lessonsFields.SortOrder, checked: true },
        { ...lessonsFields.IsHidden, checked: true },
        { ...lessonsFields.Entity, checked: true },
      ];

      this.tabs = [
        {
          id: "AllLessons1",
          isPrimary: true,
          title: "כל יחידות הלימוד",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getAllLessons,
          perPage: 500,
          fields,
          filters: [
            { ...reportsfilters.Groups, selected: this.$route.params.GroupIds },
          ],
          sortField: "sortOrder",
          sortOrder: "desc",
          checkableRows: false,
          // excelExport,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש יחידת לימוד",
          },
          sideViewEntity,
          // tableActions: (() => {
          //   let actions = [TableActions.markAsPaid, TableActions.driverAssign, TableActions.addTask];
          //   if (this.isAdmin) {
          //     actions = actions.concat([
          //       {
          //         ...TableActions.changeTransferStatusCompany,
          //         Label: "שינוי סטטוס הסבת חברה",
          //       },
          //       {
          //         ...TableActions.changeTransferStatusDriver,
          //         Label: "שינוי סטטוס הסבת נהג",
          //       },
          //       TableActions.changeReportStatus,
          //     ]);
          //     if (this.isUserProfile) {
          //       actions = actions.concat([TableActions.removeFromProfile]);
          //     }
          //   }
          //   return actions;
          // })(),
        },
        {
          id: "ActivatedLessons",
          isPrimary: false,
          title: "פעילים בלבד",
          newEntityComponent: NewReportEntity,
          getDataAsync: this.getActivatedLessons,
          perPage: 20,
          fields,
          filters: [
            { ...reportsfilters.Groups, selected: this.$route.params.GroupIds },
          ],
          sortField: "DateTaken",
          sortOrder: "desc",
          // excelExport: [
          //   {
          //     title: "יצוא טבלה",
          //     action: ({ loading, query }) => {
          //       if (!this.isLeasingCompany) query.TransferStatus = [];
          //       const newQuery = this.getFromStatusApproved(query);
          //       newQuery.isPaid = false;
          //       newQuery.ResultType = null;
          //       this.export({ loading, query: { ...newQuery } });
          //     },
          //   },
          // ],
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש יחידת לימוד",
          },
          checkableRows: true,
          sideViewEntity,
          // tableActions: (() => {
          //   let actions = [
          //     TableActions.approvePay,
          //     TableActions.disApprovePay,
          //     TableActions.markAsPaid,
          //     TableActions.driverAssign,
          //     TableActions.addTask
          //   ];
          //   if (this.isAdmin) {
          //     actions = actions.concat([
          //       {
          //         ...TableActions.changeTransferStatusCompany,
          //         Label: "שינוי סטטוס הסבת חברה",
          //       },
          //       {
          //         ...TableActions.changeTransferStatusDriver,
          //         Label: "שינוי סטטוס הסבת נהג",
          //       },
          //       TableActions.changeReportStatus,
          //     ]);
          //     if (this.isUserProfile) {
          //       actions = actions.concat([TableActions.removeFromProfile]);
          //     }
          //   }
          //   return actions;
          // })(),
        },
        // {
        //   id: "ApprovedReports",
        //   isPrimary: true,
        //   title: "מאושרים לתשלום",
        //   newEntityComponent: NewReportEntity,
        //   getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
        //     const query = rest;
        //     query.IsPaid = false;
        //     if (!this.isLeasingCompany) query.TransferStatus = [];
        //     console.log("query:", query);
        //     const newQuery = this.getFromStatusApproved(query);
        //     console.log("newQuery:", newQuery);
        //     setPermanentQuery({
        //       // IsPaid: false,
        //       ...newQuery,
        //     });

        //     return this.getAllLessons({ cancelToken, ...newQuery });
        //   },
        //   perPage: 20,
        //   fields,
        //   filters: [
        //     { ...reportsfilters.ReportStatus },
        //     { ...reportsfilters.ReportStatusApproved, selected: true },
        //     { ...reportsfilters.CauseIds },
        //     { ...reportsfilters.Alerts },
        //     { ...reportsfilters.VehicleType },
        //     { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
        //     { ...reportsfilters.Driver },
        //     {
        //       ...reportsfilters.TransferStatusWNotTransferred,
        //       selected: [
        //         0, 1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
        //         19,
        //       ],
        //       isHidden: !this.isLeasingCompany,
        //     },
        //     {
        //       ...reportsfilters.DriverTransferStatusWNotTransferred,
        //     },
        //     { ...reportsfilters.ReportNumber },
        //     { ...reportsfilters.MuniIdNumber },
        //     { ...reportsfilters.PlateNumber },
        //     { ...reportsfilters.Municipality },
        //     {
        //       ...reportsfilters.DateTypes,
        //       selected: FilterService.DateSearchTypes.DateTaken,
        //       Options: this.isLeasingCompany
        //         ? reportsfilters.DateTypes.Options
        //         : [
        //           { ...FilterService.DateSearchTypes.DateTaken },
        //           { ...FilterService.DateSearchTypes.LastPayDate },
        //           { ...FilterService.DateSearchTypes.PaymentDate },
        //           { ...FilterService.DateSearchTypes.ApprovalDate },
        //           { ...FilterService.DateSearchTypes.VerificationDate },
        //           { ...FilterService.DateSearchTypes.CreateDate },
        //           {
        //             ...FilterService.DateSearchTypes
        //               .FirstDocumentTransferCompleteDate,
        //           },
        //           {
        //             ...FilterService.DateSearchTypes
        //               .FirstDocumentTransferDriverCompleteDate,
        //           },
        //         ],
        //     },
        //   ],
        //   sortField: "DateTaken",
        //   sortOrder: "desc",
        //   excelExport: [
        //     {
        //       title: "יצוא טבלה",
        //       action: ({ loading, query }) => {
        //         const newQuery = this.getFromStatusApproved(query);
        //         newQuery.ResultType = null;
        //         this.export({ loading, query: { ...newQuery } });
        //       },
        //     },
        //   ],
        //   fastSearch: true,
        //   fastSearchOptions: {
        //     propName: "ReportNumber",
        //     label: "חיפוש לפי מספר דוח",
        //   },
        //   checkableRows: true,
        //   sideViewEntity,
        //   tableActions: (() => {
        //     let actions = [
        //       TableActions.approvePay,
        //       TableActions.disApprovePay,
        //       TableActions.markAsPaid,
        //       TableActions.driverAssign,
        //       TableActions.addTask
        //     ];
        //     if (this.isAdmin) {
        //       actions = actions.concat([
        //         {
        //           ...TableActions.changeTransferStatusCompany,
        //           Label: "שינוי סטטוס הסבת חברה",
        //         },
        //         {
        //           ...TableActions.changeTransferStatusDriver,
        //           Label: "שינוי סטטוס הסבת נהג",
        //         },
        //         TableActions.changeReportStatus,
        //       ]);
        //       if (this.isUserProfile) {
        //         actions = actions.concat([TableActions.removeFromProfile]);
        //       }
        //     }
        //     return actions;
        //   })(),
        // },
        // {
        //   id: "PaidReports",
        //   title: "דוחות ששולמו",
        //   newEntityComponent: NewReportEntity,
        //   getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
        //     const query = rest;

        //     query.IsPaid = true;
        //     setPermanentQuery({
        //       IsPaid: true,
        //     });

        //     return this.getAllLessons({ cancelToken, ...query });
        //   },
        //   excelExport: [
        //     {
        //       title: "יצוא טבלה",
        //       action: ({ loading, query }) => {
        //         const obj = { ...query };
        //         obj.IsPaid = true;
        //         this.export({ loading, query: obj }, true);
        //       },
        //     },
        //   ],
        //   perPage: 20,
        //   fields,
        //   filters: [
        //     // { ...reportsfilters.IsPaid },
        //     // { ...reportsfilters.ReportStatus },
        //     // { ...reportsfilters.NotReportStatus },
        //     // { ...reportsfilters.Alerts },
        //     { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
        //     { ...reportsfilters.Driver },
        //     { ...reportsfilters.ReportNumber },
        //     { ...reportsfilters.MuniIdNumber },
        //     { ...reportsfilters.Municipality },
        //     { ...reportsfilters.PlateNumber },
        //     { ...reportsfilters.VehicleType },
        //     // {
        //     //   ...reportsfilters.DriverTransferStatusWNotTransferred,
        //     // },
        //     // {
        //     //   ...reportsfilters.TransferStatusWNotTransferred,
        //     //   isHidden: !this.isLeasingCompany,
        //     // },
        //     {
        //       ...reportsfilters.DateTypes,
        //       selected: FilterService.DateSearchTypes.DateTaken,
        //       Options: this.isLeasingCompany
        //         ? reportsfilters.DateTypes.Options
        //         : [
        //           { ...FilterService.DateSearchTypes.DateTaken },
        //           { ...FilterService.DateSearchTypes.LastPayDate },
        //           { ...FilterService.DateSearchTypes.PaymentDate },
        //           { ...FilterService.DateSearchTypes.ApprovalDate },
        //           { ...FilterService.DateSearchTypes.VerificationDate },
        //           { ...FilterService.DateSearchTypes.CreateDate },
        //           {
        //             ...FilterService.DateSearchTypes
        //               .FirstDocumentTransferCompleteDate,
        //           },
        //           {
        //             ...FilterService.DateSearchTypes
        //               .FirstDocumentTransferDriverCompleteDate,
        //           },
        //         ],
        //     },
        //   ],
        //   sortField: "DateTaken",
        //   sortOrder: "desc",
        //   fastSearch: true,
        //   fastSearchOptions: {
        //     propName: "SearchTerm",
        //     label: "חפש מספר דוח/אסמכתה",
        //   },
        //   sideViewEntity,
        // },
        // {
        //   id: "SalaryReport",
        //   newEntityComponent: NewReportEntity,
        //   title: 'דו"ח שכר',
        //   getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
        //     const query = rest;

        //     query.IsPaidByDriver = true;
        //     setPermanentQuery({
        //       IsPaidByDriver: true,
        //     });

        //     return this.getAllLessons({ cancelToken, ...query });
        //   },
        //   excelExport: [
        //     {
        //       title: "יצוא טבלה",
        //       action: ({ loading, query }) => {
        //         const obj = { ...query };
        //         obj.IsPaidByDriver = true;
        //         obj.ResultType = '';
        //         this.export({ loading, query: obj });
        //       },
        //     },
        //   ],
        //   perPage: 20,
        //   fields,
        //   filters: [
        //     { ...reportsfilters.IsPaid },
        //     { ...reportsfilters.ReportStatus },
        //     { ...reportsfilters.CauseIds },
        //     { ...reportsfilters.Alerts },
        //     { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
        //     { ...reportsfilters.Driver },
        //     { ...reportsfilters.ReportNumber },
        //     { ...reportsfilters.MuniIdNumber },
        //     { ...reportsfilters.Municipality },
        //     { ...reportsfilters.PlateNumber },
        //     {
        //       ...reportsfilters.DateTypes,
        //       selected: FilterService.DateSearchTypes.DateTaken,
        //       Options: [
        //         { ...FilterService.DateSearchTypes.DateTaken },
        //         { ...FilterService.DateSearchTypes.PaymentDate },
        //         {
        //           ...FilterService.DateSearchTypes
        //             .FirstDocumentTransferCompleteDate,
        //         },
        //         {
        //           ...FilterService.DateSearchTypes
        //             .FirstDocumentTransferDriverCompleteDate,
        //         },
        //       ],
        //     },
        //   ],
        //   sortField: "DateTaken",
        //   sortOrder: "desc",
        //   fastSearch: true,
        //   fastSearchOptions: {
        //     propName: "ReportNumber",
        //     label: "חיפוש לפי מספר דוח",
        //   },
        //   sideViewEntity,
        // },
        // {
        //   id: "NotMyReports",
        //   newEntityComponent: NewReportEntity,
        //   title: "דוחות שלא שלי",
        //   getDataAsync: ({ cancelToken, setPermanentQuery, ...rest }) => {
        //     const query = rest;
        //     setPermanentQuery({
        //       ...query,
        //     });

        //     return this.getAllLessons({ cancelToken, ...query });
        //   },
        //   excelExport: [
        //     {
        //       title: "יצוא טבלה",
        //       action: ({ loading, query }) => {
        //         const obj = { ...query };
        //         obj.ResultType = null;
        //         this.export({ loading, query: obj });
        //       },
        //     },
        //   ],
        //   perPage: 20,
        //   fields,
        //   filters: [
        //     { ...reportsfilters.IsPaid },
        //     { ...reportsfilters.ReportStatus },
        //     { ...reportsfilters.CauseIds },
        //     { ...reportsfilters.Alerts, selected: 1 },
        //     { ...reportsfilters.Profile, isHidden: !this.isLeasingCompany },
        //     { ...reportsfilters.Driver },
        //     { ...reportsfilters.ReportNumber },
        //     { ...reportsfilters.MuniIdNumber },
        //     { ...reportsfilters.Municipality },
        //     { ...reportsfilters.PlateNumber },
        //     {
        //       ...reportsfilters.DateTypes,
        //       selected: FilterService.DateSearchTypes.DateTaken,
        //       Options: [
        //         { ...FilterService.DateSearchTypes.DateTaken },
        //         { ...FilterService.DateSearchTypes.PaymentDate },
        //         {
        //           ...FilterService.DateSearchTypes
        //             .FirstDocumentTransferCompleteDate,
        //         },
        //         {
        //           ...FilterService.DateSearchTypes
        //             .FirstDocumentTransferDriverCompleteDate,
        //         },
        //       ],
        //     },
        //   ],
        //   sortField: "DateTaken",
        //   sortOrder: "desc",
        //   fastSearch: true,
        //   fastSearchOptions: {
        //     propName: "ReportNumber",
        //     label: "חיפוש לפי מספר דוח",
        //   },
        //   sideViewEntity,
        // },
      ];
    },
    addLesson() {
      Modal.open({
        component: DialogAddLesson,
        props: {
          reportNumbers: [{ Id: 1, reportNumber: 123 }, { Id: 2, reportNumber: 3456 }],
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    reOrderLessons() {
      this.isLoading = true;
      LessonService.organizeLessons(this.tabs[0].items)
      .then(() => {
        this.$refs.entity.onNewEntitySave(this.tabs[0].items);
          // this.isLoading = false;
        }).finally(() => {
          this.isLoading = false;
        })
    },
    async onPlateNumberClick(context) {
      if (context.item.IsVehicleMine) {
        context.default();
      } else {
        const contract = await CoursesService.getReportContract(context.item.Id);
        this.$refs.entity
          .setExtraSideMenu({
            component: VehicleContractEntity,
            title: "חוזה לרכב",
            recordUrl: "contracts",
            preview: true,
            readOnly: true,
            Id: contract.data.VehicleContractId,
          })
          .show();
      }
    },
    showNewEntity() {
      this.$refs.entity.newEntityMenu();
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    export({ loading, query }, isPaidReportsTab) {
      loading(true);
      let currExport = "exportXsl";
      if (isPaidReportsTab) currExport = "exportPaidReportsXsl";
      CoursesService[currExport](query)
        // CoursesService.exportXsl(query)
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway");
        })
        .finally(() => {
          loading(false);
        });
    },
    exportByMunic({ loading, query }) {
      loading(true);
      CoursesService.exportReportsXslBy(query, "Municipalities")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי עיריות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    exportByClient({ loading, query }) {
      loading(true);
      CoursesService.exportReportsXslBy(query, "Profiles")
        .then((r) => {
          this.saveFile(r.data, "יצוא דוחות ממערכת Betterway מקובץ לפי לקוחות");
        })
        .catch(() => { })
        .finally(() => {
          loading(false);
        });
    },
    getAllLessons({ cancelToken, ...rest }) {
      return CoursesService.getCourse(
        this.courseId,
        {
          ...rest,
        },
        false,
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getActivatedLessons({ cancelToken, ...rest }) {
      return CoursesService.getCourse(
        this.courseId,
        {
          ...rest,
        },
        true,
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getPayments({ cancelToken }) {
      return CoursesService.getPayments(cancelToken.token).then((r) => ({
        data: {
          TotalResults: r.data.length,
          Items: r.data,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    saveFile(data, filename) {
      const blob = new Blob([data], { type: this.excelMimeType });
      saveAs(blob, `${filename}.xlsx`);
    },
    getFromStatusApproved(query) {
      // eslint-disable-next-line eqeqeq
      if (typeof query.StatusApproved == "boolean") {
        if (query.StatusApproved) {
          if (query.Status) {
            if (!query.Status.includes("ApproveForPayment")) {
              query.Status.push("ApproveForPayment");
            }
          } else query.Status = ["ApproveForPayment"];
        } else {
          if (query.Status) {
            query.Status = query.Status.filter(
              (item) => item !== "ApproveForPayment"
            );
          }
          query.NotStatus = ["ApproveForPayment"];
        }
        delete query.StatusApproved;
      }
      if (query.StatusApproved === null) {
        delete query.NotStatus;
        if (!query.Status) delete query.Status;
        else {
          query.Status = query.Status.filter(
            (item) => item !== "ApproveForPayment"
          );
        }
        delete query.StatusApproved;
      }
      return query;
    },
  },
  watch: {
    isReady(value) {
      if (value) {
        this.create();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.link-button {
  background: #ff7a59;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}

.button-close {
  background: white;
  color: #ff7a59;
  border: none;
  border: 1px solid #ff7a59;
  padding: 13px 30px;
  border-radius: 3px;
  outline: none;
  cursor: pointer;
}
</style>
