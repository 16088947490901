var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isReady
    ? _c(
        "div",
        { staticClass: "Reports__View" },
        [
          _vm.isLoading
            ? [
                _c(
                  "div",
                  { staticClass: "loading" },
                  [
                    _c("b-loading", {
                      attrs: { "is-full-page": false, "can-cancel": false },
                      model: {
                        value: _vm.isLoading,
                        callback: function($$v) {
                          _vm.isLoading = $$v
                        },
                        expression: "isLoading"
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.tabs.length
            ? _c("EntityListLayout", {
                ref: "entity",
                attrs: { tabs: _vm.tabs },
                on: { onNewEntitySave: _vm.onNewEntitySave },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "title",
                      fn: function() {
                        return [_vm._v("יחידות לימוד")]
                      },
                      proxy: true
                    },
                    {
                      key: "button",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                gap: "10px"
                              }
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "link-button",
                                  on: { click: _vm.addLesson }
                                },
                                [_vm._v(" הוספת יחידת לימוד לקורס ")]
                              ),
                              _c(
                                "a",
                                {
                                  staticClass: "button-close",
                                  on: { click: _vm.reOrderLessons }
                                },
                                [_vm._v(" סדר יחידות לימוד מחדש ")]
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "counter",
                      fn: function(slotProps) {
                        return [
                          _vm._v(' סה"כ '),
                          _c("b", [
                            _vm._v(_vm._s(_vm.getTotalRecords(slotProps)) + " ")
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  2495815557
                )
              })
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }